[type=text],
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea {
  @apply shadow-sm focus:outline-none focus:ring-primary focus:border-primary block w-full text-base border-gray-400 rounded-md mt-1;
}
[type=text].error,
[type=email].error,
[type=url].error,
[type=password].error,
[type=number].error,
[type=date].error,
[type=datetime-local].error,
[type=month].error,
[type=search].error,
[type=tel].error,
[type=time].error,
[type=week].error,
[multiple].error,
textarea.error {
  @apply border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500;
}

select {
  @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-400 focus:outline-none focus:ring-primary focus:border-primary rounded-md;
}

[type=checkbox],
[type=radio] {
  @apply focus:ring-primary h-4 w-4 mr-2 text-primary border-gray-400;
}
[type=checkbox].error,
[type=radio].error {
  @apply border-red-600;
}

[type=checkbox] {
  @apply rounded;
}

.control-label {
  @apply block text-base font-medium text-gray-700;
}
.control-label a {
  @apply underline;
}
.has-error .control-label {
  @apply text-red-600;
}

.help-block,
.password-suggestions {
  @apply mt-2 text-base text-gray-600;
}
.help-block a,
.password-suggestions a {
  @apply underline;
}

.form-group {
  @apply mb-4;
}

.form-wrapper {
  @apply mt-8;
}

.confirm-parent,
.password-parent {
  @apply w-auto;
}

.password-strength {
  @apply hidden lg:block text-sm text-gray-600;
}

div.password-confirm {
  @apply float-none w-auto lg:float-right text-sm text-gray-600;
}

.marker,
.form-required {
  @apply text-red-600;
}

legend,
.field-group-div h3 {
  @apply block border-b border-gray-200 text-gray-900 text-xl mt-8 mb-2 p-1 w-full normal-case font-bold;
}

label {
  @apply font-medium;
}

#edit-legal .help-block {
  @apply text-gray-700;
}

#edit-field-user-newsletter {
  @apply mt-0;
}
#edit-field-user-newsletter .form-wrapper {
  @apply mt-0;
}
#edit-field-user-newsletter .help-block {
  @apply text-gray-700;
}

.captcha .fieldset-description {
  @apply text-gray-700;
}

.user-form .form-radios,
#legal-login .file-radios {
  @apply flex items-center;
}

.container-inline-date .date-padding {
  @apply flex;
}
.container-inline-date .date-padding .form-group {
  @apply mr-5;
}

#edit-field-user-privacy-und,
#edit-field-user-fit-marketing-und,
#edit-field-user-fit-profiling-und,
#edit-field-user-fit-marketing-partner-und,
#edit-field-user-atp-marketing-und,
#edit-field-user-atp-profiling-und,
#edit-field-user-atp-marketing-partner-und,
#edit-field-user-disabled-privacy-und {
  @apply flex-row-reverse justify-end;
}

.chosen-container,
#edit-customer-profile-billing-field-billing-legal-address .chosen-container {
  display: block !important;
  background-image: none;
}

.form-wrapper > .fieldset-wrapper,
.field-group-fieldset > .fieldset-wrapper,
#edit-legal > .fieldset-wrapper > .checkbox,
#edit-legal > .fieldset-wrapper > .form-group,
#field-user-newsletter-add-more-wrapper,
.captcha > .fieldset-wrapper,
#edit-locale > .fieldset-wrapper,
.field-group-div > div {
  @apply px-4;
}

#edit-legal > .fieldset-wrapper,
#edit-field-user-newsletter-und-0 > .fieldset-wrapper,
.date-combo > .fieldset-wrapper {
  @apply px-0;
}

#field-user-indirizzo-add-more-wrapper legend {
  display: none;
}

.file-widget .btn {
  margin-left: 1rem;
}

.form-radios .radio {
  margin: 0.5rem;
}

.user-form .form-widget,
#legal-login .file-widget {
  display: flex;
  align-items: center;
}

#edit-field-user-newsletter-und-0 legend {
  display: none;
}

.form-managed-file {
  display: flex;
  align-items: center;
}

.street-block .form-group:last-child {
  display: none;
}

.field-name-field-bonifico-expire-alert {
  display: inline-block;
  width: 48.888888%;
  margin: 10px 4px;
  padding: 15px;
  border: 2px solid #eaeaea;
}

.field-dati-iban {
  display: inline-block;
  width: 48.888888%;
  margin: 10px 4px;
  padding: 15px;
  border: 2px solid #eaeaea;
}

#edit-field-user-indirizzo-und-0-postal-code {
  width: 30% !important;
}

#edit-field-data-di-nascita-und-0-value {
  width: 100%;
  padding: 0;
}
#edit-field-data-di-nascita-und-0-value .form-group {
  display: inline-block;
  margin-right: 5%;
  width: 30%;
}
#edit-field-data-di-nascita-und-0-value .form-group:last-of-type {
  margin-right: 0;
}

#field-data-di-nascita-add-more-wrapper .date-no-float {
  width: 100%;
}

.chosen-container-single .chosen-single {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  padding: 0 0 0 0 !important;
}

.field-name-field-billing {
  display: none;
}

#edit-customer-profile-billing-field-billing-type-und {
  @apply md:flex md:justify-start;
  /* nasconde N/D o N/A nella scelta di fatturazione */
}
#edit-customer-profile-billing-field-billing-type-und .radio {
  padding-right: 30px;
}
#edit-customer-profile-billing-field-billing-type-und .radio:first-child {
  display: none;
}
#edit-customer-profile-billing-field-billing-type-und .control-label[for=edit-customer-profile-billing-field-billing-type-und-none] {
  display: none;
}

.payment-details {
  font-size: 0.9em;
}

/* Ticket refund */
#ticket-refund-node-form .field-name-field-ticket-refund-amount {
  display: inline-block;
  max-width: 200px;
}

#ticket-refund-node-form .form-radios .control-label,
#ticket-refund-node-form .form-radios,
#ticket-refund-node-form .form-widget {
  display: flex;
  align-items: center;
}

#ticket-refund-node-form .button__description {
  margin-top: 1rem;
}